import { Token } from 'constant';

import { action, autorun, makeAutoObservable } from 'mobx';
import user from './user';
import { ReferralPoints } from 'gql/type';
import { getGraphRequestApi } from 'gql';
import BigNumber from 'bignumber.js';

const InitReferrals: ReferralPoints = {
    referrals: 0,
    stakedByInvitees: 0,
    points: 0,
};

interface ConfimModal {
    isOpen: boolean;
    attemptingTxn: boolean;
    hash: string;
    pendingText: string;
    errorMessage?: string;
    addTokenToMetamask?: Token;
    summery?: string;
}

export const InitConfirmModalConfig = {
    isOpen: false,
    attemptingTxn: false,
    hash: '',
    pendingText: '',
    errorMessage: undefined,
    addTokenToMetamask: undefined,
};

export class Application {
    openConnectWallet: boolean = false;
    confirmModal: ConfimModal = InitConfirmModalConfig;
    hideMainnet: boolean = true;
    // comingSoon: boolean = true;
    comingSoon: boolean = false;
    preStaking: boolean = false;
    preStakingEndDate: number = new Date().getTime() + 30000;
    isPreStakingModalOpen: boolean = false;
    fantasyPhase1End: boolean = true;
    referrals: ReferralPoints = InitReferrals;
    stakingPoints: number = 0;
    constructor() {
        makeAutoObservable<this>(this);
        autorun(() => {
            if (user.chainId) {
                this.globalPoints();
            }
        });
    }

    toggleConnectWalletModal = () => {
        this.openConnectWallet = !this.openConnectWallet;
    };

    setConfimModal = (data: ConfimModal) => {
        this.confirmModal = data;
    };
    setCloseConfirModalState = () => {
        this.confirmModal = InitConfirmModalConfig;
    };

    setIsPreStakingModalOpen = (isOpen: boolean) => {
        this.isPreStakingModalOpen = isOpen;
    };

    setReferrals = (res: ReferralPoints) => {
        this.referrals = res;
    };

    setStakingPoints = (points: number) => {
        this.stakingPoints = points;
    };

    @action globalPoints = async () => {
        try {
            const res = await getGraphRequestApi().globalPoints();
            this.setStakingPoints(
                new BigNumber(res.globalStakingPoints.points)
                    .shiftedBy(-18)
                    .toNumber(),
            );
            this.setReferrals({
                points: new BigNumber(res.globalReferrals?.points || 0)
                    .shiftedBy(-18)
                    .toNumber(),
                referrals: Number(res.globalReferrals.referrals),
                stakedByInvitees: new BigNumber(
                    res.globalReferrals?.stakedByInvitees || 0,
                )
                    .shiftedBy(-18)
                    .toNumber(),
            });
        } catch (e) {
            console.error('globalPoints error');
        }
    };
}

export default new Application();
