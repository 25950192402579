import Button from 'components/Button';
import Modal from 'components/Modal';
import React from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
    width: 600px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    `};
`;

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
}
export default function RiseAlert({ isOpen, onClose, onConfirm }: Props) {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <Wrapper>
                <TYPE.main
                    fontSize={24}
                    fontWeight={600}
                    textAlign={'center'}
                    paddingBottom={18}
                    mobileFz={20}
                >
                    Risk Alert
                </TYPE.main>
                <TYPE.main fontSize={18} mobileFz={14}>
                    This action will convert your eMetis into seMetis and
                    automatically accumulate rewards from decentralized
                    sequencer nodes. However, when you wish to convert seMetis
                    back into eMetis to realize your rewards,
                    <b> 30% (including principal + rewards)</b> of your eMetis
                    will be sent to a vesting contract. To reclaim this 30%, you
                    will need to stake a certain amount of ENKI tokens,
                    <b>(10 ENKI tokens vest 1 eMetis in 90 days)</b>
                    <br />
                    <br />
                    This rule is designed to ensure the long-term stability of
                    sequencer staking and is intended for users who align with
                    the values of ENKI and aim to earn decentralized sequencer
                    node rewards over the long term.
                    <br />
                    <br />
                    Please proceed with caution.
                </TYPE.main>
                <Button
                    type="primary"
                    onClick={() => {
                        onClose();
                        onConfirm();
                    }}
                >
                    I understand, continue
                </Button>
            </Wrapper>
        </Modal>
    );
}
