import React from 'react';
import styled from 'styled-components';
import MetisLogo from 'assets/images/token/metis.svg';

import EnkiLogo from 'assets/images/token/enki.svg';
import Button from 'components/Button';
import { Medium, Telegram, twitter } from 'common-helpers/img';
import FantasyTips from 'components/FantasyTips';

const Wrapper = styled.div`
    position: fixed;
    top: 60%;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    position: relative;

        margin-top: 57%;
  `};
`;

const Placeholdertext = styled.div`
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #eac578;
    width: 320px;
    line-height: 21px;
    text-align: center;
    vertical-align: middle;
    ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 90%;
  box-sizing: border-box;
  padding: 0 10px;
  `};
    line-height: 1.8;
`;

const JoinText = styled.div`
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #eac578;
    line-height: 21px;
    margin-top: 100px;
    opacity: 0.5;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 20px;
    `};
`;

const MediaWrapper = styled.div`
    margin-top: 24px;
    padding-bottom: 20px;

    .link {
        position: relative;
        z-index: 1;
        display: inline-block;
        height: fit-content;
    }
`;

const MediaImgWrapper = styled.div`
    width: 28px;
    height: 28px;
    border-radius: 14px;
    margin: 0 12px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    img,
    svg {
        width: 100%;
        height: 100%;
    }
`;

const WhiteBg = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: #000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -16px);
    z-index: 0;
`;

const TokenImg = styled.img`
    width: 18px;
    height: 18px;
    vertical-align: sub;
`;

const StyledButton = styled(Button)`
    background: rgba(220, 164, 92, 1) !important;
    box-shadow: rgba(219, 154, 50, 0.3) 2px 10px 10px 0px;
    color: rgb(0, 0, 0);
    padding: 15px 24px;
    font-size: 18px;
    height: 64px;
    border-radius: 16px !important;
    box-sizing: border-box;
    font-weight: bold;
    margin-top: 30px;
`;

const StyledFantasyTips = styled(FantasyTips)`
    margin: 0 auto;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 100px;
    `};
`;

export default function ComingSoon() {
    return (
        <>
            {/* <StyledFantasyTips /> */}

            <Wrapper>
                <Placeholdertext>
                    Seamlessly Stake&nbsp;
                    <TokenImg src={MetisLogo} />
                    &nbsp;Metis, Earn Rewards, and Embrace DeFi with&nbsp;
                    <TokenImg src={EnkiLogo} />
                    &nbsp;ENKI Protocol.
                </Placeholdertext>
                <StyledButton
                    onClick={() => {
                        window.open('https://testnet.enkixyz.com/');
                    }}
                >
                    Explore Testnet
                </StyledButton>
                <JoinText>Join our community</JoinText>
                <MediaWrapper>
                    <a
                        href="https://twitter.com/ENKIProtocol"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <MediaImgWrapper>
                            <img src={twitter} alt="" />
                        </MediaImgWrapper>
                    </a>
                    <a
                        href="https://t.me/enkixyz"
                        target="_blank"
                        className="link"
                        rel="noreferrer"
                    >
                        <MediaImgWrapper>
                            <Telegram />
                        </MediaImgWrapper>
                        <WhiteBg />
                    </a>
                    <a
                        href="https://medium.com/@ENKIProtocol"
                        target="_blank"
                        rel="noreferrer"
                        className="link"
                    >
                        <MediaImgWrapper>
                            <Medium />
                        </MediaImgWrapper>
                        <WhiteBg />
                    </a>
                </MediaWrapper>
            </Wrapper>
        </>
    );
}
